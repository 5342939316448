<template>
  <div>
    <div class="banner" v-if="areaYear >= '2025'">
      <img class="ban-img pc" src="@/assets/img/2025/banner-full-pc.png" alt="">
      <img class="ban-img mob" src="@/assets/img/2025/banner-full-mob.png" alt="">
      <!-- 2025 时间地点文字设计使用了类似DIN的字体，直接修改图片更为便捷 -->
      <!-- <div class="infos">
        <div class="time">
          <span class="tag font-da">Date.</span>
          <div>
            <p>
              <span class="font-da y">{{ global.formatTime(startTime, 'Y') }}</span><br>
              <span class="font-da m">{{ global.formatTime(startTime, 'M') }}</span>
              <span class="font-da d">{{ global.formatTime(startTime, 'D') }}</span>
            </p>
            <p>
              <span class="font-da y">{{ global.formatTime(endTime, 'Y') }}</span><br>
              <span class="font-da m">{{ global.formatTime(endTime, 'M') }}</span>
              <span class="font-da d">{{ global.formatTime(endTime, 'D') }}</span>
            </p>
          </div>
        </div>
        <div class="address" v-if="hotel">
          <span class="tag font-ali-r">Add.</span>
          {{ location }} {{ locationCity }}
        </div>
        <div class="address" v-else>
          {{ locationCity }}
        </div>
      </div> -->
    </div>
    <div class="banner" v-else-if="areaYear == '2024'">
      <img class="ban-img-mid" src="@/assets/img/2024/home_banner_pc_bg_mid_660.jpg" alt="">
      <!-- <img class="ban-img mob" src="@/assets/img/2024/giac_2024_mob.png" alt=""> -->
      <div class="main pc">
        <img class="title" src="@/assets/img/2024/home_banner_pc_title.png" alt="">
        <div class="time">
          <p>
            <img src="@/assets/img/shijian.png" alt="" /> {{ global.formatTime(startTime, 'Y.M.D') }}-{{ global.formatTime(endTime, 'D') }}
          </p>
          <p>
            <img src="@/assets/img/didian.png" alt="" />{{ address }}
          </p>
        </div>
        <div class="btns">
          <p class="act" v-if="areaYear==theYear" @click="toOrder()">GIAC {{city||'深圳'}}站报名中</p>
          <p class="act" v-else-if="theYear" @click="toNext(`/${theYear}`)">前往 GIAC {{theYear}}{{city?` ${city}站`:''}}</p>
          <!-- <p @click="toNext(`/${areaYear}/home`)">GIAC 深圳站</p> -->
        </div>
      </div>
      <div class="main mob">
        <img class="title" src="@/assets/img/2024/home_banner_pc_title.png" alt="">
        <div class="time">
          <p>
            <img src="@/assets/img/shijian.png" alt="" /> {{ global.formatTime(startTime, 'Y.M.D') }}-{{ global.formatTime(endTime, 'D') }}
          </p>
          <p>
            <img src="@/assets/img/didian.png" alt="" />{{ address }}
          </p>
        </div>
      </div>
    </div>
    <div class="banner" v-else>
      <div class="main">
        <img src="@/assets/img/logo2022.png" alt="" />
        <h1>全球互联网架构大会<p>GLOBAL INTERNET ARCHITECTURE CONFERENCE</p></h1>
        <p>
          <span>连接未来的技术架构</span>
        </p>
        <div class="time">
          <p>
            <!-- <i class="glyphicon glyphicon-time"></i> -->
            <img src="@/assets/img/shijian.png" alt="" /> {{ global.formatTime(startTime, 'Y.M.D') }}-{{ global.formatTime(endTime, 'D') }}
          </p>
          <p>
            <!-- <i class="glyphicon glyphicon-map-marker"></i> -->
            <img src="@/assets/img/didian.png" alt="" />{{ address }}
          </p>
        </div>
        <div class="btns">
          <p class="act" v-if="areaYear==theYear" @click="toOrder()">GIAC {{(city)||'深圳'}}站报名中</p>
          <p class="act" v-else-if="theYear" @click="toNext(`/${theYear}`)">前往 GIAC {{theYear.slice(0,4)}}{{city?` ${city}站`:''}}</p>
          <!-- <p @click="toNext(`/${areaYear}/home`)">GIAC 深圳站</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    year: {
      type: Array,
      default: () => { return [] }
    },
    times: {
      type: Array,
      default: () => { return [] }
    },
    address: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: ''
    }
  },
  computed: {
    areaYear () {
      return (this.year && this.year[0] && this.year[0].toString().trim().slice(0,4)) || ''
    },
    theYear () {
      return (this.year && this.year[1] && this.year[1].toString().trim().slice(0,4)) || ''
    },
    location () {
      return (this.address && this.address.toString().trim().split(',')[0]) || ''
    },
    locationCity () {
      return (this.location && (this.location.split(' ')[1] || this.location.split(' ')[0])) || this.city || ''
    },
    hotel () {
      return (this.address && this.address.toString().trim().split(',')[1]) || ''
    },
    startTime () {
      return (this.times && this.times[0]) || ''
    },
    endTime () {
      return (this.times && this.times[1]) || ''
    }
  },
  methods: {
    toNext: function(path, query) {
      this.$router.push({ path: path, query: query||{} });
    },
  }
}
</script>

<style lang="scss">
.home_ {
  .banner {
    width: 100%;
    height: 777px;
    padding: 130px 0 0 0;
    // height: 685px;
    // padding: 135px 0 0 0;
    background-color: #5051E9;
    // background-image: url('../../assets/img/2024/giac_2024_pc.jpg');
    // background-image: url("../../assets/img/giac2022_banner_777.png");
    background-image: url("../../assets/img/giac2022_banner_777_small.png");
    // background-image: url("https://f.msup.com.cn/meeting/giac2022_banner_777.png");
    // background-image: var(--home-banner);
    background-repeat: no-repeat;
    background-position: right;
    // background-size: auto 100%;
    background-size: cover;
    position: relative;
    .main {
      width: 100%;
      max-width: 1240px;
      margin: 0 auto;
      padding: 0 20px;
      h1 {
        width: fit-content;
        min-height: 80px;
        line-height: 80px;
        font-size: 80px;
        font-family: Source Han Sans CN Heavy;
        font-weight: 800;
        color: #ffffff;
        margin-top: 35px;
        margin-bottom: 0;
        p {
          width: 100%;
          min-height: 20px;
          line-height: 20px;
          margin-top: 25px;
          margin-bottom: 0;
          font-size: 20px;
          font-family: Arial;
          font-weight: bold;
          color: #ffffff;
          letter-spacing: 5px;
          text-align-last: justify;
        }
      }
      > p {
        min-height: 20px;
        line-height: 20px;
        margin-top: 50px;
        margin-bottom: 0;
        font-size: 20px;
        font-family: Arial;
        font-weight: bold;
        color: #ffffff;
        span {
          height: 67px;
          line-height: 67px;
          font-size: 40px;
          font-family: Alibaba PuHuiTi Medium;
          font-weight: 500;
          color: #ffffff;
          padding-right: 27px;
          background: linear-gradient(
            -90deg,
            rgba(78, 168, 151, 0.96) 10%,
            rgba(72, 73, 223, 0.72) 65%,
            rgba(69, 74, 206, 0.59) 100%
          );
          border-radius: 34px;
          display: inline-block;
        }
      }
      div.time {
        margin-top: 45px;
        display: flex;
        flex-wrap: nowrap;
        p {
          min-height: 25px;
          line-height: 25px;
          font-size: 25px;
          font-family: Alibaba PuHuiTi Regular; // Light
          font-weight: bold;
          color: #ffffff;
          margin-right: 40px;
          margin-bottom: 0;
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          img {
            width: auto;
            height: 30px;
            margin-right: 15px;
          }
        }
      }
      div.btns {
        margin-top: 40px;
        display: flex;
        flex-wrap: nowrap;
        p {
          width: 264px;
          line-height: 66px;
          margin-right: 30px;
          font-size: 20px;
          // font-family: Source Han Sans CN Regular;
          font-family: Alibaba PuHuiTi Regular;
          font-weight: 400;
          text-align: center;
          color: #FFFFFF;
          background: linear-gradient(0deg, #0029BC 0%, #495FFF 100%);
          border-radius: 8px;
          cursor: pointer;
        }
        p.act {
          color: #3942B7;
          background: #FFFFFF;
        }
      }
    }
  }
  &.year_2024 {
    .banner {
      height: 660px;
      padding-top: 0;
      background-image: url('../../assets/img/2024/home_banner_pc_bg.jpg');
      &::before, &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: auto 100%;
      }
      &::before {
        left: 0;
        background-image: url('../../assets/img/2024/home_banner_pc_bg_l.png');
        background-position: left bottom;
      }
      &::after {
        right: 0;
        background-image: url('../../assets/img/2024/home_banner_pc_bg_r.png');
        background-position: right top;
      }
      .ban-img-mid {
        display: none;
      }
      .main {
        position: absolute;
        // top: 130px;
        top: 12%;
        left: 50%;
        z-index: 3;
        transform: translateX(-50%);
        img.title {
          max-width: 750px;
        }
        div.time {
          margin-top: 90px;
          p {
            font-size: 28px;
          }
        }
        div.btns {
          // margin-top: 45px;
          margin-top: 40px;
          p {
            margin-bottom: 0;
          }
        }
        &.mob {
          height: 100%;
          padding-top: 30px;
          top: 0;
          img.title {
            height: auto;
          }
          .time {
            // width: 100%;
            margin-top: 0;
            position: absolute;
            bottom: 25px;
            left: 20px;
            // display: flex;
            p {
              font-size: 16px;
              img {
                height: 16px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    @media (max-width: 1600px) {
      .banner {
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
        &::before, &::after {
          display: none;
        }
        .ban-img-mid {
          width: 100%;
          display: block;
          position: relative;
          z-index: 1;
        }
        .main {
          position: absolute;
          z-index: 3;
          img.title {
            width: calc(750 / 1400 * 100%);
          }
          div.btns {
            margin-top: 30px;
          }
          div.time {
            margin-top: 77px;
            p {
              font-size: 24px;
              img {
                height: 24px;
              }
            }
          }
        }
      }
    }
    @media (max-width: 1240px) {
      .banner {
        .main {
          div.btns {
            margin-top: 25px;
            p {
              width: 220px;
              line-height: 50px;
            }
          }
          div.time {
            margin-top: 50px;
            p {
              font-size: 20px;
              img {
                height: 20px;
              }
            }
          }
        }
      }
    }
    @media (max-width: 1024px) {
      .banner {
        .main {
          div.time {
            margin-top: 30px;
            p {
              font-size: 18px;
              img {
                height: 18px;
              }
            }
          }
          div.btns {
            margin-top: 15px;
            p {
              width: 200px;
              line-height: 40px;
              font-size: 18px;
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .banner {
        min-height: 0;
        // .ban-img-mid {
        //   display: none;
        // }
        .ban-img {
          width: 100%;
        }
      }
    }
    @media (max-width: 540px) {
      .banner {
        .main.mob {
          .time p {
            font-size: 13px;
            img {
              height: 13px;
            }
          }
        }
      }
    }
  }
  &.year_2025,
  &.year_2026 {
    .banner {
      height: 613px;
      padding-top: 0;
      text-align: center;
      background: #C9EBFA;
      position: relative;
      z-index: 3;
      // --mid-width: 1366px; // 1200
      --mid-width: 1514px; // 1330
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 1;
        transform: translateX(calc(-100% - var(--mid-width) / 2));
        width: calc(50% - var(--mid-width) / 2);
        min-width: 130px;
        max-width: 260px;
        height: 60%;
        // background: linear-gradient(to bottom, transparent 0%, #A8E6F7 100%);
        background: radial-gradient(circle at bottom right, #A8E6F7 0%, transparent 50%, transparent 100%);
        mix-blend-mode: color-burn;
      }
      img.ban-img {
        width: 100%;
        max-width: var(--mid-width);
        margin: 0 auto;
        position: relative;
        z-index: 3;
      }
      div.infos,
      div.infos .time,
      div.infos .time div,
      div.infos .address {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-end;
      }
      div.infos {
        position: absolute;
        bottom: 10.7%;
        // bottom: 40px;
        left: 50%;
        z-index: 4;
        transform: translateX(calc(var(--mid-width) * (0.0604 - 0.5)));
        * {
          line-height: 1;
          margin-top: 0;
          margin-bottom: 0;
          color: pink;
        }
        span.tag {
          display: block;
          // padding-bottom: 1px;
          font-size: 16px;
          // transform: scaleX(0.97) scaleY(1.1);
          transform: scaleX(0.96);
          transform-origin: left bottom;
        }
        .time {
          div {
            p {
              text-align: left;
            }
          }
        }
        .address {
          // 
        }
      }
      @media (max-width: 1514px) {
        height: auto;
        div.infos {
          left: 6%;
          transform: none
        }
      }
      @media (max-width: 767px) {
        min-height: 0;
        div.infos {
          left: 7.25%;
          bottom: 7.2%;
        }
      }
    }
  }
}
</style>